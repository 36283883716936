
.App-header {
    display: flex;
    flex-direction: row;
    align-items:center;
    min-height: 10%;
    font-size: calc(10px + 1vmin);
    background-image: url("../assets/grupusaites-header-hor-dark-1.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90% 90%;
    z-index: 10;
    position:"fixed";
  }

  .filterListIcon {
    margin: 0vh 3vh 0vh 3vh;
  }
  .aboutUsIcon {
    margin: 0vh 3vh 0vh 3vh;
  }

  .headerTitle{
    flex: 1;
  }
