.optionsLayout{
    display: flex;
    flex-direction: column;
    vertical-align: top;
    align-items: center;
    margin: 2vh;
    position: absolute;
    left: 0%;
    width: fit-content;
    max-height: 84vh;
    max-width: 40vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
    background-color: rgba(215, 211, 211, 0.8);
    border-radius: 10px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    z-index: 999;
}
.accordion{
    left: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    width: 85%;
    margin: 2px;
    overflow-y: auto;
    overflow-x: hidden;    
    border-radius: 5px !important;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    background-color: rgb(215, 211, 211) !important;
}