.loadingContainer{
    height:100%;
    background-color: #34363A;
    }
    .loadingDots{
      height: auto;
    }
    
    .loadLogo{
      margin-top: 5vh;
      max-height: 70%;
      max-width: 70%;
    }

