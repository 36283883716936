.mainContainer{
  text-align: center;
  display: flex;
  flex-direction: column;
  height:100vh;
}

.contentLayout {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: relative;
}

.bandSearchField {
  position: absolute;
  top: 2%;
  right:2%;
  min-width: 35%;
  max-width: 75%;
  background-color:rgb(215, 211, 211, 0.3);
}

.graphContainer{
  width:100%;
  max-height: 100%;
}

.stackedContainer{
  position: fixed; /* Fixed to bottom-right of the screen */
  bottom: 2%; /* Anchor point at the bottom */
  right: 2.5%; /* Anchor point at the right */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-end; /* Align items to the right */
  gap: 10px; /* Add spacing between components */
}


