.musicianInfoContainer {
    max-height: 32vh;
    max-width: 40vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    padding: 5px;
    background-color: rgb(215, 211, 211)
  }
  
  .title {
    font-size: x-large;
    border-color: black;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    position: sticky;
  }
  
  .memberTitle {
    position: relative;
    left: 0px;
    font-size: medium;
  }
  
  .memberText {
    display: flex;
    flex-direction: row;
  }