.bandSearchField
{
  max-width: 20vw;
}

/* Media query for mobile devices */
@media (max-width: 800px) { /* Adjust breakpoint as needed */
    .bandSearchField {
      width: 45vw; /* Wider width for mobile screens */
    }
  }
