.aboutUsInfoContainer {
    position: absolute;
    height: 83%;
    width: auto;
    max-width: fit-content;
    top: 12%;
    margin-left: auto;
    margin-right: auto;
    left: 2.75%;
    right: 2.75%;
    bottom: 5%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    background-color: rgb(206, 200, 200, 0.95);
    word-wrap: break-word;
    text-align: justify;
    text-justify: inter-word;
    padding-left: 5%;
    padding-right: 5%;  
    padding-top: 1%;
    padding-bottom: 1%;
  }
  
  .aboutTitle {
    font-size: x-large;
    position: sticky;
  }

  .aboutSubtitle {
    font-size: medium;
    position: sticky;
    white-space: nowrap;   
  }  

  .aboutProject {
    font-size: medium;
    position: sticky;
  }

  .aboutStaff {
    position: relative;
    height: auto;
    width: auto;
    font-size: large;
    position: sticky;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px;
    
  }

  .grid-item {
    padding: 10px;
    font-size: medium;
    text-align: left;
    }

    @media screen and (min-width: 64em) {
      .aboutContributors {
          max-width: 25%; /* Larger size for PC */
          font-size: medium;
          position: sticky;
          text-align: justify;          
          word-spacing: normal;
      }
  }

  @media screen and (min-width: 40em) {  
    .aboutContributors {
      max-width: 40%;
      font-size: medium;
      position: sticky;
      text-align: justify;
    }
  }

  .contributors {
    list-style-type: none
  }

  .aboutSuggestions {
    max-width: 85%;
    font-size: medium;
    position: sticky;
    text-align: justify;
  } 

  /* Apply grayscale filter to the image by default */
  .teamImage {
    width: 225px;
    height: 225px;
    max-width: 100%;
    filter: grayscale(100%);
    transition: filter 0.3s, transform 0.3s;
    transform: scale(1); /* Larger size on desktop */    
  }
/* Revert to normal color and slightly increase size on hover (desktop) */
@media (min-width: 768px) {
  .teamImage:hover {
      filter: grayscale(0%);
      transform: scale(1.1); /* Larger size on desktop */
  }
}

/* Keep the same size on mobile */
@media (max-width: 767px) {
  .teamImage {
    width: 130px;
    height: 130px;
    max-width: 100%;
    filter: grayscale(0%);
  }
}
