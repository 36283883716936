

.container {
  width:100%;
  height:100%;
  position: relative;
  background-color: white;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.6);
}

.container svg {
  display: block;
  width: 100%;
  height: 100%;
}

.node text {
  font: 12px sans-serif;
}

div.tooltip {
  position: absolute;
  text-align: center;
  width: 110px;
  padding: 10px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0;
  border-radius: 8px;
  pointer-events: none;
}